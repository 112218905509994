import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'

const Producto = ({ nombre, imagen }) => {
  const data = useStaticQuery(graphql` 
  query {
    allImageSharp {
      edges {
        node {
          fluid (maxWidth: 700) {
            src
            srcSet
            originalName
          }
          fixed (width: 450, height:600){
            src
            srcSet
            originalName
          }
        }
      }
    }
  }
  `)
  const imageQuery = data.allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === imagen
  )
  return (
    <Grid item xs={12} sm={4} md={3} style={{ display: 'flex', justifyContent: 'center' }} >
      <Prod>
        <CardActionArea>
          {!!imageQuery &&
            <CardMedia
              style={{ backgroundImage: `url(${imageQuery.node.fluid.src})`, height: '380px' }}
            />
          }
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {nombre}
            </Typography>
            {/* {!!descripcion &&
              <Typography variant="body2" color="textSecondary" component="p">
                {descripcion}
              </Typography>
            } */}
          </CardContent>
        </CardActionArea>
      </Prod>
    </Grid>
  )
}

export default Producto

const Prod = styled(Card)`
  width: 90%;
  @media (min-width: 540px){
    width: 100%;
  }

`